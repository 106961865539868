import PlanSideNavigation from "../PlanSideNavigation";
import "../../Plan.css";
import { Button } from "../../../Button";
import { useState, useEffect } from "react";
import "./Workouts.css";
import {
  createWorkoutFromTemplate,
  createWorkout,
  searchWorkouts,
} from "../../../../api/WorkoutEndpoints";
import { searchWorkoutTemplates } from "../../../../api/WorkoutTemplateEndpoints";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Select from "react-select";
import Card from "@mui/material/Card";
import { TablePagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AuthenticatedResource from "../../../AuthenticatedResource";

function Workout() {
  const navigate = useNavigate();
  const handleCreateNewClickOpen = () => {
    setCreateNewOpen(true);
  };
  const handleCreateFromTemplateClickOpen = () => {
    setCreateFromTemplateOpen(true);
  };
  const [createNewOpen, setCreateNewOpen] = useState(false);
  const [createFromTemplateOpen, setCreateFromTemplateOpen] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [workouts, setWorkouts] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getWorkouts(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (props) => {
    setRowsPerPage(props.target.value);
    setPage(0);
    getWorkouts(props.target.value, 0);
  };

  const handleCreateNewClose = () => {
    setCreateNewOpen(false);
  };

  const handleCreateFromTemplateClose = () => {
    setCreateFromTemplateOpen(false);
  };

  async function handleCreateNewCommit() {
    const nameEl = document.getElementById("new-name");
    await createWorkout(nameEl.value);
    setCreateNewOpen(false);
    getWorkouts();
  };

  async function handleTemplateSelect(data) {
    setSelectedTemplate(data);
  }

  async function handleCreateFromTemplateCommit() {
    const nameEl = document.getElementById("name-from-template");
    await createWorkoutFromTemplate(selectedTemplate.id, nameEl.value);
    setCreateFromTemplateOpen(false);
    getWorkouts();
  }

  async function getTemplates() {
    searchWorkoutTemplates()
      .then((response) => {
        setTemplates(response.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getWorkouts(size, pageNumber) {
    searchWorkouts(size, pageNumber)
      .then((response) => {
        setTotalElements(response.totalElements);
        setWorkouts(response.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getTemplates();
    getWorkouts(rowsPerPage, page);
  }, [rowsPerPage, page]);

  return (
    <>
      <AuthenticatedResource />
      <div className="plan">
        <PlanSideNavigation />
        <div className="plan-content">
          <div className="plan-content-header">
            <div className="rows">
              <div className="row-item">
                <Button
                  buttonStyle=""
                  buttonSize="btn--medium2"
                  onClick={handleCreateNewClickOpen}
                >
                  New Workout
                </Button>
              </div>
              <div className="row-item">
                <Button
                  buttonStyle=""
                  buttonSize="btn--medium2"
                  onClick={handleCreateFromTemplateClickOpen}
                >
                  From Template
                </Button>
              </div>
            </div>
            <Dialog open={createNewOpen} onClose={handleCreateNewClose}>
              <DialogTitle>Create New Workout</DialogTitle>
              <DialogContent>
                <TextField
                  sx={{
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "black",
                    },
                    "& .MuiInputBase-root": {
                      color: "black",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#2A363B",
                    },
                  }}
                  autoFocus
                  margin="dense"
                  id="new-name"
                  label="Name"
                  fullWidth
                  variant="standard"
                  color="info"
                />
              </DialogContent>
              <DialogActions>
                <div className="dialog-row">
                  <Button
                    buttonSize="btn--dialog"
                    buttonStyle="btn--dialog"
                    onClick={handleCreateNewClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    buttonSize="btn--dialog"
                    buttonStyle="btn--dialog"
                    onClick={handleCreateNewCommit}
                  >
                    Create
                  </Button>
                </div>
              </DialogActions>
            </Dialog>

            <Dialog
              open={createFromTemplateOpen}
              onClose={handleCreateFromTemplateClose}
            >
              <DialogTitle>Create Workout From Template</DialogTitle>
              <DialogContent>
                <Select
                  options={templates}
                  getOptionLabel={(option) => option.name}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  placeholder="Select Template"
                  value={selectedTemplate}
                  onChange={handleTemplateSelect}
                  maxHeight={500}
                  className="dialog-dropdown"
                  color="green"
                />
                <TextField
                  sx={{
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "black",
                    },
                    "& .MuiInputBase-root": {
                      color: "black",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#2A363B",
                    },
                  }}
                  autoFocus
                  margin="dense"
                  id="name-from-template"
                  label="Name"
                  fullWidth
                  variant="standard"
                  color="info"
                />
              </DialogContent>
              <DialogActions>
                <div className="dialog-row">
                  <Button
                    buttonSize="btn--dialog"
                    buttonStyle="btn--dialog"
                    onClick={handleCreateFromTemplateClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    buttonSize="btn--dialog"
                    buttonStyle="btn--dialog"
                    onClick={handleCreateFromTemplateCommit}
                  >
                    Create
                  </Button>
                </div>
              </DialogActions>
            </Dialog>
          </div>

          <div className="overview-exercises-list">
            <Card sx={{ marginTop: 1 }}>
              <table className="table">
                <tbody>
                  <tr className="table-header">
                    <td className="workout-table-column">Name</td>
                    <td className="workout-table-column">Created</td>
                    <td className="workout-table-column">Started</td>
                    <td className="workout-table-column">Finished</td>
                  </tr>
                  {workouts.map((workout) => {
                    return (
                      <tr
                        className="workout-table-row"
                        key={workout.id}
                        onClick={() => navigate("/plan/workouts/" + workout.id)}
                      >
                        <td className="workout-table-column">{workout.name}</td>
                        <td className="workout-table-column">
                          {workout.createdTime}
                        </td>
                        <td className="workout-table-column">
                          {workout.startedTime}
                        </td>
                        <td className="workout-table-column">
                          {workout.finishedTime}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalElements}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default Workout;
