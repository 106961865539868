import { useEffect, useState } from "react";
import AuthenticatedResource from "../../../AuthenticatedResource";
import PlanSideNavigation from "../PlanSideNavigation";
import {
  searchExercisesPageable,
  createExercise,
} from "../../../../api/ExerciseEndpoints";
import Card from "@mui/material/Card";
import { TablePagination } from "@mui/material";
import TextField from "@mui/material/TextField";
import "./Exercises.css";
import { Button } from "../../../Button";
import Dialog from "@mui/material/Dialog";
import Select from "react-select";

function Exercises() {
  const [exercises, setExercises] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedEquipment, setSelectedEquipment] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
    setSelectedCategory(null);
    setSelectedEquipment(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function handleCategorySelect(data) {
    setSelectedCategory(data);
  }

  async function handleEquipmentSelect(data) {
    setSelectedEquipment(data);
  }

  const handleCommit = async () => {
    const nameEl = document.getElementById("name");
    const categoryEl = selectedCategory;
    const EquipmentEl = selectedEquipment;

    await create(nameEl?.value, categoryEl?.value, EquipmentEl?.value);
    setOpen(false);
  };

  const categories = [
    { value: "BACK", label: "Back" },
    { value: "BICEPS", label: "Biceps" },
    { value: "CARDIO", label: "Cardio" },
    { value: "CHEST", label: "Chest" },
    { value: "CORE", label: "Core" },
    { value: "FOREARMS", label: "Forearms" },
    { value: "FULL_BODY", label: "Full body" },
    { value: "LEGS", label: "Legs" },
    { value: "OLYMPIC", label: "Olympic" },
    { value: "SHOULDER", label: "Shoulder" },
    { value: "TRICEPS", label: "Triceps" },
  ];

  const equipment = [
    { value: "BAND", label: "Band" },
    { value: "BARBELL", label: "Barbell" },
    { value: "CABLE", label: "Cable" },
    { value: "DUMBBELL", label: "Dumbbell" },
    { value: "MACHINE", label: "Machine" },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleChange(searchString, null, rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (props) => {
    setRowsPerPage(props.target.value);
    setPage(0);
    handleChange(searchString, null, rowsPerPage, page);
  };

  async function handleChange(searchString, category, rowsPerPage, page) {
    let response = await searchExercisesPageable(
      searchString,
      category,
      rowsPerPage,
      page
    );
    setTotalElements(response.totalElements);
    setExercises(response.content);
  }

  async function create(name, category, equipment) {
    await createExercise(name, category, equipment);
    handleChange(searchString, null, rowsPerPage, page);
  }

  async function handleExerciseSearchString(data) {
    let searchString = document.getElementById("name_search").value;
    setSearchString(searchString);
    setPage(0);
    let exercises = await searchExercisesPageable(
      searchString,
      null,
      rowsPerPage,
      page
    );
    setTotalElements(exercises.totalElements);
    setExercises(exercises.content);
  }

  useEffect(() => {
    async function getExercises() {
      searchExercisesPageable(searchString, null, rowsPerPage, page)
        .then((response) => {
          setTotalElements(response.totalElements);
          setExercises(response.content);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    getExercises();
  }, [page, rowsPerPage, searchString]);

  return (
    <>
      <AuthenticatedResource />
      <div className="plan">
        <PlanSideNavigation />
        <div className="plan-content">
          <div className="plan-content-header">
            <Button
              buttonStyle=""
              buttonSize="btn--medium2"
              onClick={handleClickOpen}
            >
              Create Exercise
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <div className="dialog">
                <div className="dialog-header-row">
                  <h2 className="dialog-title">Create Exercise</h2>
                </div>
                <div className="dialog-rows">
                  <div className="dialog-row">
                    <TextField
                      hiddenLabel
                      id="name"
                      size="small"
                      color="info"
                      fullWidth
                      label="Name"
                      variant="standard"
                    />
                  </div>
                  <div className="dialog-row">
                    <Select
                      options={categories}
                      menuPlacement="auto"
                      menuPosition="fixed"
                      placeholder="Select category"
                      value={selectedCategory}
                      onChange={handleCategorySelect}
                      maxHeight={500}
                      className="dialog-dropdown"
                      color="green"
                    />
                  </div>
                  <div className="dialog-row">
                    <Select
                      options={equipment}
                      menuPlacement="auto"
                      menuPosition="fixed"
                      placeholder="Select equipment"
                      value={selectedEquipment}
                      onChange={handleEquipmentSelect}
                      maxHeight={500}
                      className="dialog-dropdown"
                      color="green"
                    />
                  </div>
                  <div className="dialog-row">
                    <Button
                      buttonSize="btn--dialog"
                      buttonStyle="btn--dialog"
                      onClick={handleClose}
                    >
                      CANCEL
                    </Button>
                    <Button
                      buttonSize="btn--dialog"
                      buttonStyle="btn--dialog"
                      onClick={handleCommit}
                    >
                      CREATE
                    </Button>
                  </div>
                </div>
              </div>
            </Dialog>
          </div>
          <div className="overview-exercises-list">
            <Card sx={{ marginTop: 1 }}>
              <table className="table">
                <tbody>
                  <tr className="table-header">
                    <td className="exercises-table-column">
                      <div className="exercise-search-textfield">
                        <TextField
                          sx={{
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "black",
                            },
                            "& .MuiInputBase-root": {
                              color: "black",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#2A363B",
                            },
                          }}
                          autoFocus
                          margin="dense"
                          id="name_search"
                          label="Name"
                          variant="standard"
                          color="info"
                          onChange={handleExerciseSearchString}
                        />
                      </div>
                    </td>
                    <td>Category</td>
                  </tr>
                  {exercises?.map((exercise) => {
                    return (
                      <tr
                        className="workout-table-row"
                        key={exercise.id}
                        onClick={() =>
                          console.log("Clicked exercise " + exercise.id)
                        }
                      >
                        <td className="workout-table-column">
                          {exercise.name}
                        </td>
                        <td className="workout-table-column">
                          {
                            categories.find(
                              (category) =>
                              category.value === exercise.category
                            ).label
                          }
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalElements}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default Exercises;
