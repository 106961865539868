import React from 'react';
import './Button.css'

const STYLES = ['btn--test', 'btn--primary', 'btn--outline', 'btn--outline-secondary', 'btn--dialog', 'edit-btn-icon-style', 'edit-btn-style']

const SIZES = ['btn--medium', 'btn--large', 'btn--medium2', 'btn--full-width', 'btn--dialog', 'edit-btn-icon-size', 'edit-btn-size']

export const Button = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize
}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    return (
        <button
            className={`btn ${checkButtonStyle} ${checkButtonSize}`}
            onClick={onClick}
            type={type}
        >
            {children}
        </button>
    )
}