import { API_BASE_URL, ACCESS_TOKEN } from "../auth/constants";
import { Request, NullRequest } from './APIUtils';

export function getDashboardData() {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
    }
  
    return Request({
      url: API_BASE_URL + "/dashboard/",
      method: "GET",
    });
  }