import React from "react";
import { ACCESS_TOKEN } from "../../constants";
import { useSearchParams, Navigate } from "react-router-dom";

function OAuth2RedirectHandler() {
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const error = searchParams.get("error");

  if (token) {
    localStorage.setItem(ACCESS_TOKEN, token);
    return (
      <Navigate
        to={{
          pathname: "/plan/workouts"
        }}
      />
    );
  } else {
    return (
      <Navigate
        to={{
          pathname: "/login",
          state: {
            from: this.props.location,
            error: error,
          },
        }}
      />
    );
  }
}

export default OAuth2RedirectHandler;
