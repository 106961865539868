import { API_BASE_URL, ACCESS_TOKEN } from "../auth/constants";
import { Request, NullRequest } from './APIUtils';

export function createWorkoutTemplate(name) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return Request({
    url: API_BASE_URL + "/workouttemplate",
    method: "POST",
    body: JSON.stringify({ name: name }),
  });
}

export function deleteWorkoutTemplate(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return NullRequest({
    url: API_BASE_URL + "/workouttemplate/" + id,
    method: "DELETE"
  });
}

export function searchWorkoutTemplates() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return Request({
    url: API_BASE_URL + "/workouttemplate",
    method: "GET",
  });
}

export function getWorkoutTemplate(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return Request({
    url: API_BASE_URL + "/workouttemplate/" + id,
    method: "GET",
  });
}

export function addExerciseToWorkoutTemplate(
  templateId,
  exerciseIds,
  superSet
) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return Request({
    url: API_BASE_URL + "/workouttemplate/" + templateId + "/addExercises",
    method: "POST",
    body: JSON.stringify({ exerciseIds: exerciseIds, superSet: superSet }),
  });
}

export function removeExerciseFromWorkoutTemplate(
  templateId,
  templateExerciseId
) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return Request({
    url:
      API_BASE_URL +
      "/workouttemplate/" +
      templateId +
      "/exercise/" +
      templateExerciseId,
    method: "DELETE",
  });
}

export function addSetToTemplateExercise(templateId, templateExerciseId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return NullRequest({
    url:
      API_BASE_URL +
      "/workouttemplate/" +
      templateId +
      "/exercise/" +
      templateExerciseId +
      "/set",
    method: "POST",
  });
}

export function removeSetFromTemplateExercise(
  templateId,
  templateExerciseId,
  setId
) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return Request({
    url:
      API_BASE_URL +
      "/workouttemplate/" +
      templateId +
      "/exercise/" +
      templateExerciseId +
      "/set/" +
      setId,
    method: "DELETE",
  });
}

export function updateTemplateSet(
  templateId,
  templateExerciseId,
  setId,
  body
) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return NullRequest({
    url:
      API_BASE_URL +
      "/workouttemplate/" +
      templateId +
      "/exercise/" +
      templateExerciseId +
      "/set/" +
      setId,
    method: "PUT",
    body: body
  });
}

export function renameRoutine(id, name) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return NullRequest({
    url: API_BASE_URL + "/workouttemplate/" + id,
    method: "PATCH",
    body: JSON.stringify({ name: name })
  });
}