import React, { Component } from "react";
import "../components/pages/Login.css";
import '../components/Button.css';
import { GOOGLE_AUTH_URL } from "./constants";
import { Navigate } from "react-router-dom";
import googleLogo from "./img/google-logo.png";
import { Button } from "../components/Button";

class GoogleAuthentication extends Component {
  render() {
    if (this.props.authenticated) {
      return (
        <Navigate
          to={{
            pathname: "/",
            state: { from: this.props.location }
          }}
        />
      );
    }

    return (
        <a className='btn btn--test btn--large' href={GOOGLE_AUTH_URL}>
          Google
        </a>
    );
  }
}

export default GoogleAuthentication;