import { API_BASE_URL, ACCESS_TOKEN } from "../auth/constants";
import { Request } from './APIUtils';

export function searchExercises(name, category) {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
    }
    let url = API_BASE_URL + "/exercise?size=1000"
    
    if(name != null) {
      url += "&name=" + name
    }
    if(category != null) {
      url += "&category=" + category
    }

    return Request({
      url: url,
      method: "GET"
    });
  }

  export function searchExercisesPageable(name, category, size, page) {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
    }

    let url = API_BASE_URL + "/exercise?page=" + (page ? page : 0) + "&size=" +  (size ? size : 10);
    
    if(name != null) {
      url += "&name=" + name
    }
    if(category != null) {
      url += "&category=" + category
    }

    return Request({
      url: url,
      method: "GET"
    });
  }

  export function createExercise(name, category, equipment) {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
    }

    let url = API_BASE_URL + "/exercise";

    return Request({
      url: url,
      method: "POST",
      body: JSON.stringify({ name: name, category: category, equipment: equipment }),
    });
  }