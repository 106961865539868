import "../../App.css";
import "../../components/Dialog.css";

function Community() {
  return (
    <>
      <h2>COMMUNITY</h2>
    </>
  );
}

export default Community;
