import "../../App.css";
import AuthenticatedResource from "../AuthenticatedResource";

function Listen() {

  return (
    <>
      <AuthenticatedResource />
      <div className="posts-container">
        LISTEN
      </div>
    </>
  );
}

export default Listen;
