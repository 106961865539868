import React from "react";
import { useState, useEffect } from "react";
import * as MdIcons from "react-icons/md";
import { Link } from "react-router-dom";
import "../../SideNavigation.css";

function ProfileSideMenu() {
  const [activeMenu, setActiveMenu] = useState();

  useEffect(() => {
    if (window.location.pathname.includes("profile/account")) {
      setActiveMenu("account");
    } else {
      setActiveMenu("settings");
    }
  }, []);

  return (
    <div className="sidebar">
      <nav className="side-nav-menu active">
        <ul className="side-nav-menu-items">
          <li
            className={
              activeMenu === "settings"
                ? "side-nav-list-item-selected"
                : "side-nav-list-item"
            }
            onClick={() => setActiveMenu("settings")}
          >
            <Link to="/profile/settings" className="side-menu-name-icon">
              <MdIcons.MdSettings />
              <span className="side-nav-text">Settings</span>
            </Link>
          </li>
          <li
            className={
              activeMenu === "account"
                ? "side-nav-list-item-selected"
                : "side-nav-list-item"
            }
            onClick={() => setActiveMenu("account")}
          >
            <Link to="/profile/account" className="side-menu-name-icon">
              <MdIcons.MdAccountCircle />
              <span className="side-nav-text">Account</span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default ProfileSideMenu;
