import React, { useEffect, useState, useCallback } from "react";
import "../../../../App.css";
import { AiOutlineClose } from "react-icons/ai";
import {
  getWorkout,
  removeSetFromWorkoutExercise,
  updateWorkoutSet,
} from "../../../../api/WorkoutEndpoints";
import TextField from "@mui/material/TextField";
import { Checkbox } from "@mui/material";
import { Popup } from "../../../popup/Popup";
import { Tooltip } from "@material-ui/core";
import { setTypes } from "../../../constants/SetTypes";
import { debounce } from "lodash";

import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

function WorkoutExerciseRow(props) {
  const [weightValue, setWeightValue] = useState(props.set.weight);
  const [repValue, setRepValue] = useState(props.set.reps);
  const [distanceValue, setDistanceValue] = useState(props.set.distance);
  const [timeValue, setTimeValue] = useState(props.set.time);
  const [setType, setSetType] = useState();
  const [setTypeDialogOpen, setSetTypeDialogOpen] = useState(false);
  const [executedValue, setExecutedValue] = useState(props.set.executed);
  const isImperial = localStorage.getItem("measurementSystem") === "IMPERIAL";

  const delayedHandleWeightValue = useCallback(
    debounce(async (event) => {
      await handleWeightValue(event);
    }, 500),
    []
  );

  const delayedHandleRepValue = useCallback(
    debounce(async (event) => {
      await handleRepValue(event);
    }, 500),
    []
  );

  const delayedHandleDistanceValue = useCallback(
    debounce(async (event) => {
      await handleDistanceValue(event);
    }, 500),
    []
  );

  const delayedHandleTimeValue = useCallback(
    debounce(async (event) => {
      await handleTimeValue(event);
    }, 500),
    []
  );

  useEffect(() => {
    setWeightValue(props.set.weight);
    setRepValue(props.set.reps);
    setSetType(props.set.setType);
    setExecutedValue(props.set.executed);
  }, [props.set.weight, props.set.reps, props.set.setType, props.set.executed]);

  const handleRemoveSetFromWorkoutExercise = async (
    id,
    workoutExerciseId,
    setId
  ) => {
    await removeSetFromWorkoutExercise(id, workoutExerciseId, setId);
    props.setWorkout(await getWorkout(id));
  };

  async function handleWeightValue(event) {
    const body = JSON.stringify({ weight: event.target.value });
    await updateWorkoutSet(
      props.workout.id,
      props.exercise.id,
      props.set.id,
      body
    );
    props.setWorkout(await getWorkout(props.workout.id));
  }

  async function handleRepValue(event) {
    const body = JSON.stringify({ reps: event.target.value });
    await updateWorkoutSet(
      props.workout.id,
      props.exercise.id,
      props.set.id,
      body
    );
    props.setWorkout(await getWorkout(props.workout.id));
  }

  async function handleDistanceValue(event) {
    const body = JSON.stringify({ distance: event.target.value });
    await updateWorkoutSet(
      props.workout.id,
      props.exercise.id,
      props.set.id,
      body
    );
    props.setWorkout(await getWorkout(props.workout.id));
  }

  async function handleTimeValue(event) {
    const body = JSON.stringify({ time: event.target.value });
    await updateWorkoutSet(
      props.workout.id,
      props.exercise.id,
      props.set.id,
      body
    );
    props.setWorkout(await getWorkout(props.workout.id));
  }

  async function handleExecutedValue(data) {
    setExecutedValue(data.target.checked);
    const body = JSON.stringify({ executed: data.target.checked });
    await updateWorkoutSet(
      props.workout.id,
      props.exercise.id,
      props.set.id,
      body
    );
    props.setWorkout(await getWorkout(props.workout.id));
  }

  async function handleSetTypeSelect(event) {
    setSetTypeDialogOpen(false);
    setSetType(event);
    const body = JSON.stringify({ setType: event });
    await updateWorkoutSet(
      props.workout.id,
      props.exercise.id,
      props.set.id,
      body
    );
    props.setWorkout(await getWorkout(props.workout.id));
  }

  function formatDuration(str) {
    let removeColons = str.replace(/:/g, "");
    let padded = removeColons.padStart(6, "0");
    let removed = padded.substr(-6);
    let parts = removed.match(/.{1,2}/g);
    let formatted = parts.join(":");
    return formatted;
  }

  function getSecondsFromDuration(duration) {
    let hours = duration.substr(0, 2) * 1;
    let minutes = duration.substr(3, 2) * 1;
    let seconds = duration.substr(6, 2) * 1;
    let totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  }

  return (
    <tr>
      <td className="exercise-table-sortno-column">{props.set.sortNo}</td>
      <td className="exercise-table-set-type-column">
        <Tooltip
          title={
            setType ? setTypes?.find((e) => e.value === setType)?.label : ""
          }
        >
          <h4 className={setType} onClick={() => setSetTypeDialogOpen(true)}>
            {setTypes?.find((e) => e.value === setType)?.shortText}
          </h4>
        </Tooltip>
      </td>
      <td className="exercise-table-weight-hint-column">
        <div className="tiny-text">{props.set.weightHint}</div>
      </td>
      <td className="exercise-table-weight-column">
        {props.exercise.exercise.category === "CARDIO" ? (
          <FormControl variant="standard" sx={{ m: 1, mt: 3, width: "25ch" }}>
            <Input
              id="standard-adornment-distance"
              endAdornment={
                <InputAdornment position="end">
                  {isImperial ? "mi" : "km"}
                </InputAdornment>
              }
              aria-describedby="standard-distance-helper-text"
              inputProps={{
                "aria-label": "Distance",
              }}
              onChange={(event) => {
                setDistanceValue(event.target.value);
                delayedHandleDistanceValue(event);
              }}
              value={distanceValue || ""}
              variant="standard"
              size="small"
              type="number"
            />
            <FormHelperText id="standard-distance-helper-text">
              Distance
            </FormHelperText>
          </FormControl>
        ) : (
          <FormControl variant="standard" sx={{ m: 1, mt: 3, width: "25ch" }}>
            <Input
              id="standard-adornment-weight"
              endAdornment={
                <InputAdornment position="end">
                  {isImperial ? "lbs" : "kg"}
                </InputAdornment>
              }
              aria-describedby="standard-weight-helper-text"
              inputProps={{
                "aria-label": "Weight",
              }}
              onChange={(event) => {
                setWeightValue(event.target.value);
                delayedHandleWeightValue(event);
              }}
              value={weightValue || ""}
              variant="standard"
              size="small"
              type="number"
            />
            <FormHelperText id="standard-weight-helper-text">
              Weight
            </FormHelperText>
          </FormControl>
        )}
      </td>
      <td className="exercise-table-rep-hint-column">
        <div className="tiny-text">{props.set.repHint}</div>
      </td>
      <td className="exercise-table-reps-column">
        <div className="rep-value">
          {props.exercise.exercise.category === "CARDIO" ? (
            <TextField
              hiddenLabel
              id="timeValue"
              label="Time"
              onChange={(event) => {
                setTimeValue(event.target.value);
                delayedHandleTimeValue(event);
              }}
              value={formatDuration(timeValue || "")}
              variant="standard"
              size="small"
            />
          ) : (
            <TextField
              hiddenLabel
              id="repValue"
              label="Reps"
              onChange={(event) => {
                setRepValue(event.target.value);
                delayedHandleRepValue(event);
              }}
              value={repValue || ""}
              variant="standard"
              size="small"
              type="number"
            />
          )}
        </div>
      </td>
      <td>{props.set.pace}</td>
      <td className="exercise-table-executed-column">
        <Checkbox onClick={handleExecutedValue} checked={executedValue} />
      </td>
      <td className="exercise-table-delete-column">
        <AiOutlineClose
          className="react-button"
          size={24}
          onClick={() =>
            handleRemoveSetFromWorkoutExercise(
              props.workout.id,
              props.exercise.id,
              props.set.id
            )
          }
        />
      </td>
      {setTypeDialogOpen ? (
        <Popup
          closePopup={() => setSetTypeDialogOpen(false)}
          setSetType={handleSetTypeSelect}
        />
      ) : null}
    </tr>
  );
}

export default WorkoutExerciseRow;
