import { API_BASE_URL, ACCESS_TOKEN } from "../auth/constants";
import { Request, NullRequest } from './APIUtils';
import { getTimeZone } from "../helpers/Utils";

export function createWorkout(name) {
  return Request({
    url: API_BASE_URL + "/workout",
    method: "POST",
    body: JSON.stringify({ name: name }),
  });
}

export function createWorkoutFromTemplate(templateId, name) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return Request({
    url: API_BASE_URL + "/workout/fromTemplate",
    method: "POST",
    body: JSON.stringify({ templateId: templateId, name: name }),
  });
}

export function searchWorkouts(pageSize, pageNumber) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  const page = pageNumber ? pageNumber : 0;
  const size = pageSize ? pageSize : 20;

  return Request({
    url: API_BASE_URL + "/workout?page=" + page + "&size=" + size + "&timeZoneOffset=" + getTimeZone() + "&locale=" + navigator.language,
    method: "GET",
  });
}

export function getWorkout(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return Request({
    url: API_BASE_URL + "/workout/" + id,
    method: "GET",
  });
}

export function deleteWorkout(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return NullRequest({
    url: API_BASE_URL + "/workout/" + id,
    method: "DELETE",
  });
}

export function addSetToWorkoutExercise(workoutId, exerciseId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return NullRequest({
    url: API_BASE_URL + "/workout/" + workoutId + "/exercise/" + exerciseId + "/set",
    method: "POST"
  });
}

export function updateWorkoutSet(workoutId, workoutExerciseId, setId, body) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return NullRequest({
    url:
      API_BASE_URL +
      "/workout/" +
      workoutId +
      "/exercise/" +
      workoutExerciseId +
      "/set/" +
      setId,
    method: "PUT",
    body: body,
  });
}

export function removeSetFromWorkoutExercise(workoutId, exerciseId, setId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return NullRequest({
    url:
      API_BASE_URL +
      "/workout/" +
      workoutId +
      "/exercise/" +
      exerciseId +
      "/set/" +
      setId,
    method: "DELETE",
  });
}

export function addExerciseToWorkout(workoutId, exerciseIds, superSet) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return NullRequest({
    url: API_BASE_URL + "/workout/" + workoutId + "/exercise",
    body: JSON.stringify({ exerciseIds, superSet }),
    method: "POST",
  });
}

export function removeExerciseFromWorkout(workoutId, exerciseId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return NullRequest({
    url: API_BASE_URL + "/workout/" + workoutId + "/exercise/" + exerciseId,
    method: "DELETE",
  });
}

export function startWorkout(workoutId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return NullRequest({
    url: API_BASE_URL + "/workout/" + workoutId + "/start",
    method: "PUT",
  });
}

export function finishWorkout(workoutId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return NullRequest({
    url: API_BASE_URL + "/workout/" + workoutId + "/finish",
    method: "PUT",
  });
}

export function renameWorkout(workoutId, name) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return NullRequest({
    url: API_BASE_URL + "/workout/" + workoutId,
    method: "PATCH",
    body: JSON.stringify({ name: name })
  });
}