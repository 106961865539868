import { API_BASE_URL, ACCESS_TOKEN } from "../auth/constants";
import { Request, NullRequest } from "./APIUtils";

export function searchPlans(pageSize, pageNumber) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  const page = pageNumber ? pageNumber : 0;
  const size = pageSize ? pageSize : 20;

  return Request({
    url: API_BASE_URL + "/plan?page=" + page + "&size=" + size,
    method: "GET",
  });
}

export function getPlan(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return Request({
    url: API_BASE_URL + "/plan/" + id,
    method: "GET",
  });
}

export function addRoutinesToPlan(planId, routineIds) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return NullRequest({
    url: API_BASE_URL + "/plan/" + planId + "/addRoutines",
    method: "POST",
    body: JSON.stringify({ routineIds: routineIds }),
  });
}

export function createPlan(name) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return Request({
    url: API_BASE_URL + "/plan/",
    method: "POST",
    body: JSON.stringify({ name: name }),
  });
}

export function deletePlan(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return NullRequest({
    url: API_BASE_URL + "/plan/" + id,
    method: "DELETE"
  });
}

export function deleteRoutineFromPlan(id, routineId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return NullRequest({
    url: API_BASE_URL + "/plan/" + id + "/routine/" + routineId,
    method: "DELETE"
  });
}

export function renamePlan(id, name) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return NullRequest({
    url: API_BASE_URL + "/plan/" + id,
    method: "PATCH",
    body: JSON.stringify({ name: name })
  });
}