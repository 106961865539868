import React from "react";
import "./Popup.css";
export const Popup = ({ closePopup, setSetType }) => {
  return (
    <div className="popup-container" onClick={() => closePopup()}>
     <div className="popup-body">
      <h3>Select type set</h3>
      <div className="popup-warmup" onClick={() => setSetType("WARMUP")}>Warmup</div>
      <div className="popup-normal" onClick={() => setSetType("NORMAL")}>Normal</div>
      <div className="popup-dropset" onClick={() => setSetType("DROP_SET")}>Dropset</div>
     </div>
    </div>
  );
};