import React, { useEffect, useState } from "react";
import "../../../App.css";
import { Link } from "react-router-dom";
import "../../SideNavigation.css";
import * as MdIcons from "react-icons/md";
import * as CiIcons from "react-icons/ci";
import * as GiIcons from "react-icons/gi";

function PlanSideNavigation() {
  const [activeMenu, setActiveMenu] = useState();

  useEffect(() => {
    if(window.location.pathname.includes("plans")) {
      setActiveMenu("plans");
    } else if(window.location.pathname.includes("routines")) {
      setActiveMenu("routines");
    } else if(window.location.pathname.includes("exercises")) {
      setActiveMenu("exercises");
    } else if(window.location.pathname.includes("dashboard")) {
      setActiveMenu("dashboard");
    } else {
      setActiveMenu("workouts");
    }
  }, []);

  return (
    <>
      <div className="sidebar">
        <nav className="side-nav-menu active">
          <ul className="side-nav-menu-items">
          <li className={activeMenu === "dashboard" ? "side-nav-list-item-selected" : "side-nav-list-item"} onClick={() => setActiveMenu("dashboard")}>
              <Link to="/plan/dashboard" className="side-menu-name-icon">
                <MdIcons.MdHome />
                <span className="side-nav-text">Dashboard</span>
              </Link>
            </li>
            <li className={activeMenu === "plans" ? "side-nav-list-item-selected" : "side-nav-list-item"} onClick={() => setActiveMenu("plans")}>
              <Link to="/plan/plans" className="side-menu-name-icon">
                <MdIcons.MdLibraryBooks />
                <span className="side-nav-text">Plans</span>
              </Link>
            </li>
            <li className={activeMenu === "routines" ? "side-nav-list-item-selected" : "side-nav-list-item"} onClick={() => setActiveMenu("routines")}>
              <Link to="/plan/routines" className="side-menu-name-icon">
              <MdIcons.MdOutlineLibraryBooks />
                <span className="side-nav-text">Routines</span>
              </Link>
            </li>
            <li className={activeMenu === "workouts" ? "side-nav-list-item-selected" : "side-nav-list-item"} onClick={() => setActiveMenu("workouts")}>
              <Link to="/plan/workouts" className="side-menu-name-icon">
              <CiIcons.CiDumbbell />
                <span className="side-nav-text">Workouts</span>
              </Link>
            </li>
            <li className={activeMenu === "exercises" ? "side-nav-list-item-selected" : "side-nav-list-item"} onClick={() => setActiveMenu("exercises")}>
              <Link to="/plan/exercises" className="side-menu-name-icon">
              <GiIcons.GiWeightLiftingUp />
                <span className="side-nav-text">Exercises</span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default PlanSideNavigation;
