import { API_BASE_URL, ACCESS_TOKEN } from "../auth/constants";

export const Request = (options) => {
  const headers = new Headers({
    "Content-Type": "application/json",
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem(ACCESS_TOKEN)
    );
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options)?.then((response) =>
    response?.json().then((json) => {
      if (response.status === 401 && window.location.pathname !== '/login') {
        window.location = '/login';
      }
      return json;
    })
  );
};

export const NullRequest = (options) => {
  const headers = new Headers({
    "Content-Type": "application/json",
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem(ACCESS_TOKEN)
    );
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options);
};

export function getCurrentUser() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return Request({
    url: API_BASE_URL + "/user",
    method: "GET"
  });
}

export function getSettings() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return Request({
    url: API_BASE_URL + "/user/profile/settings",
    method: "GET"
  });
}

export function getAccount() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return Request({
    url: API_BASE_URL + "/user/profile/account",
    method: "GET"
  });
}

export function updateSettings(body) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return Request({
    url: API_BASE_URL + "/user/settings",
    method: "PATCH",
    body
  });
}