import ProfileSideMenu from "./ProfileSideMenu";
import AuthenticatedResource from "../../AuthenticatedResource";
import { Button } from "../../Button";
import './Profile.css';
import { getAccount } from "../../../api/APIUtils";
import { useEffect, useState } from "react";

function Account() {
  const [user, setUser] = useState();

  async function getUser() {
    getAccount()
      .then((response) => {
        setUser(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getUser();
  }, []);

  function platformType(platform) {
    switch(platform) {
      case 'google':
        return 'Google';
      case 'appleid':
        return 'AppleID';
      case 'facebook':
        return 'Facebook';
      default:
        return 'UNKNOWN';
    }
  }

  return (
    <>
      <AuthenticatedResource />
      <div className="profile">
        <ProfileSideMenu />
        <div className="profile-content">
          <table id="settings-table">
            <tbody>
            <tr>
              <td>Account type</td>
              <td>{platformType(user?.platform)}</td>
            </tr>
            <tr>
              <td>Name</td>
              <td>{user?.name}</td>
            </tr>
            <tr>
              <td>Member since</td>
              <td>{user?.createdTime}</td>
            </tr>
            <tr>
              <td>No. of workouts</td>
              <td>{user?.noOfWorkouts}</td>
            </tr>
            </tbody>
          </table>
          
          <Button
            onClick={() => {
              localStorage.removeItem("accessToken");
              window.location.reload();
            }}
          >Sign Out</Button>
        </div>
      </div>
    </>
  );
}

export default Account;
