import React, { useState, useEffect } from "react";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";
import { Link } from "react-router-dom";
import { getCurrentUser } from "../api/APIUtils";

function HeroSection() {
  const [loggedIn, setLoggedIn] = useState(false);

  async function setLoggedInStatus() {
    getCurrentUser()
      .then((response) => {
        if(response) {
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    setLoggedInStatus();
  }, []);

  return (
    <div>
      <div className="hero-container">
        {window.innerWidth > 1150 ? (
          <h1>HEALTHIER, HAPPIER, STRONGER, FASTER</h1>
        ) : (
          <h1>HEALTHIER HAPPIER STRONGER FASTER</h1>
        )}
        <p>What are you waiting for?</p>
        <div className="hero-btns">
          {
            !loggedIn && (
            <Link to="/login">
              <Button buttonStyle="btn--primary" buttonSize="btn--full-width">
                Register / Login
              </Button>
            </Link>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
