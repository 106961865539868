import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { ACCESS_TOKEN } from "../auth/constants";
import { getCurrentUser } from "../api/APIUtils";

function Navbar() {
  const [click, setClick] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState();
  const [activeItem, setActiveItem] = useState("home");

  const handleClick = () => {
    setClick(!click);
  };
  const closeMobileMenu = (event) => {
    setActiveItem(event)
    setClick(false);
  };

  const setLoggedInStatus = () => {
    if (localStorage.getItem(ACCESS_TOKEN) != null) {
      setLoggedIn(true);
    }
  };

  async function updateUser() {
    getCurrentUser()
      .then((response) => {
        setUser(response);
        localStorage.setItem("measurementSystem", response.measurementSystem);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    setLoggedInStatus();
    updateUser();

    if (window.location.pathname.startsWith("/")) {
      setActiveItem("home");
    } else if (window.location.pathname.startsWith("/plan")) {
      setActiveItem("plan");
    } else if (window.location.pathname.startsWith("/watch")) {
      setActiveItem("watch");
    } else if (window.location.pathname.startsWith("/community")) {
      setActiveItem("community");
    } else if (window.location.pathname.startsWith("/profile")) {
      setActiveItem("profile");
    } else {
      setActiveItem("home");
    }
  }, []);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={() => closeMobileMenu}>
            HealthHub
            <i className="typewriter" />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link
                to="/"
                className={
                  activeItem === "home" ? "nav-links-selected" : "nav-links"
                }
                onClick={() => closeMobileMenu("home")}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/plan/workouts"
                className={
                  activeItem === "plan"
                    ? "nav-links-selected"
                    : "nav-links"
                }
                onClick={() => closeMobileMenu("plan")}
              >
                Plan
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/watch"
                className={
                  activeItem === "watch"
                    ? "nav-links-selected"
                    : "nav-links"
                }
                onClick={() => closeMobileMenu("watch")}
              >
                Watch
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/listen"
                className={
                  activeItem === "listen"
                    ? "nav-links-selected"
                    : "nav-links"
                }
                onClick={() => closeMobileMenu("listen")}
              >
                Listen
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/community"
                className={
                  activeItem === "community"
                    ? "nav-links-selected"
                    : "nav-links"
                }
                onClick={() => closeMobileMenu("community")}
              >
                Community
              </Link>
            </li>
            {user && (
              <li className="nav-item">
                <Link
                  to="/profile/settings"
                  className="nav-links"
                  onClick={() => closeMobileMenu("profile")}
                >
                  <img src={user?.imageUrl} alt={user?.name} />
                </Link>
              </li>
            )}
            {!loggedIn && (
              <li className="nav-item-outlined">
                <Link
                  to="/login"
                  className="nav-links-outlined"
                  onClick={() => closeMobileMenu}
                >
                  Sign In
                </Link>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
