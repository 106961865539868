import React from "react";
import "../../../../App.css";
import AuthenticatedResource from "../../../AuthenticatedResource";
import PlanSideNavigation from "../PlanSideNavigation";
import { useState, useEffect } from "react";
import { searchPlans, createPlan } from "../../../../api/PlanEndpoints";
import Card from "@mui/material/Card";
import { TablePagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CreateDialog from "../../../Dialog/CreateDialog";
import { Button } from "../../../Button";

function Plans() {
  const [plans, setPlans] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getPlans(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (props) => {
    setRowsPerPage(props.target.value);
    setPage(0);
    getPlans(props.target.value, 0);
  };

  async function getPlans(size, pageNumber) {
    searchPlans(size, pageNumber)
      .then((response) => {
        setTotalElements(response.totalElements);
        setPlans(response.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleCommit = async () => {
    const nameEl = document.getElementById("name");
    await createPlan(nameEl.value);
    setPlans(await getPlans(rowsPerPage, page));
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getPlans(rowsPerPage, page);
  }, [rowsPerPage, page]);

  return (
    <>
      <AuthenticatedResource />
      <div className="plan">
        <PlanSideNavigation />
        <div className="plan-content">
          <div className="plan-content-header">
            <Button
              buttonStyle=""
              buttonSize="btn--medium2"
              onClick={handleClickOpen}
            >
              NEW PLAN
            </Button>
            <CreateDialog
              title="Create New Template"
              open={open}
              handleClose={handleClose}
              handleCommit={handleCommit}
            />
          </div>
          <div className="overview-exercises-list">
            <Card sx={{ marginTop: 1 }}>
              <table className="table">
                <tbody>
                  <tr className="table-header">
                    <td className="workout-table-column">Name</td>
                  </tr>
                  {plans?.map((plan) => {
                    return (
                      <tr
                        className="workout-table-row"
                        key={plan.id}
                        onClick={() => navigate("/plan/plans/" + plan.id)}
                      >
                        <td className="workout-table-column">{plan.name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalElements}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default Plans;
