import { getDashboardData } from "../../../../api/DashboardEndpoints";
import { useState, useEffect } from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from "recharts";
import "./Dashboard.css";
import PlanSideNavigation from "../PlanSideNavigation";
import { Card } from "@mui/material";

function Dashboard() {
  const [dashboardData, setDashboardData] = useState();

  async function getData() {
    setDashboardData(await getDashboardData());
  }

  function findLargestNumberOfWorkouts(data) {
    return data?.workoutsPerWeek?.reduce((max, current) => {
      return Math.max(max, current.numberOfWorkouts);
    }, 0);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="plan">
        <PlanSideNavigation />
        <div className="plan-content">
          <Card sx={{ marginTop: 1 }}>
            <h2 className="dashboard-title">Workouts per week</h2>
            <BarChart
              width={350}
              height={300}
              data={dashboardData?.workoutsPerWeek}
              margin={{
                top: 20,
                right: 4,
                left: 4,
                bottom: 4,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis allowDecimals={false} />
              <Bar
                dataKey="numberOfWorkouts"
                fill="#8884d8"
                barSize={20}
                radius={[10, 10, 10, 10]}
              >
                {dashboardData?.workoutsPerWeek?.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill="#E84A5F" />
                ))}
              </Bar>
            </BarChart>
          </Card>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
