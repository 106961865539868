import { TextField } from "@mui/material";
import Select from "react-select";
import { useState } from "react";
import "./PaceCalc.css";

function PaceCalc() {
  const [hoursValue, setHoursValue] = useState(null);
  const [minutesValue, setMinutesValue] = useState(null);
  const [secondsValue, setSecondsValue] = useState(null);
  const [distanceValue, setDistanceValue] = useState(null);
  const [selectedDistanceCategory, setSelectedDistanceCategory] = useState(null);

  function handleHoursInput(data) {
    setHoursValue(data.value);
  }

  function handleMinutesInput(data) {
    setMinutesValue(data.value);
  }

  function handleSecondsInput(data) {
    setSecondsValue(data.value);
  }

  function handleDistanceInput(data) {
    setDistanceValue(data.value);
  }

  function handleDistanceCategoriesSelect(data) {
    setSelectedDistanceCategory(data);
  }

  function calculatePace() {
    //  ((seconds)/km) / 60

    console.log(selectedDistanceCategory);

    switch(selectedDistanceCategory?.value) {
      case "KM":
        return ((hoursValue * 3600 + minutesValue * 60 + secondsValue) / distanceValue) / 60;
      default: return "";
    }
    return "no pace";
  }

  const distanceCategories = [
    { value: "M", label: "Miles" },
    { value: "KM", label: "Kilometers" },
    { value: "HALF_MARATHON", label: "Half Marathon" },
    { value: "MARATHON", label: "Marathon" }
  ];

  const customStyles = {
    container: provided => ({
      ...provided,
      width: "100%"
    })
  };

  return (
    <>
      <h1>PACE CALC</h1>
      <div className="tools-content">
        <table className="table-test">
          <tr>
            <td>Time</td>
            <td>
              <TextField
                sx={{
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "black",
                  },
                  "& .MuiInputBase-root": {
                    color: "black",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#2A363B",
                  },
                }}
                autoFocus
                margin="dense"
                id="h_textfield"
                label="h"
                variant="standard"
                color="info"
                onChange={handleHoursInput}
              />
            </td>
            <td>
              <TextField
                sx={{
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "black",
                  },
                  "& .MuiInputBase-root": {
                    color: "black",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#2A363B",
                  },
                }}
                autoFocus
                margin="dense"
                id="m_textfield"
                label="m"
                variant="standard"
                color="info"
                onChange={handleMinutesInput}
              />
            </td>
            <td>
              <TextField
                sx={{
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "black",
                  },
                  "& .MuiInputBase-root": {
                    color: "black",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#2A363B",
                  },
                }}
                autoFocus
                margin="dense"
                id="s_textfield"
                label="s"
                variant="standard"
                color="info"
                onChange={handleSecondsInput}
              />
            </td>
          </tr>
          <tr>
            <td>Distance</td>
            <td>
              <TextField
                sx={{
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "black",
                  },
                  "& .MuiInputBase-root": {
                    color: "black",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#2A363B",
                  },
                }}
                autoFocus
                margin="dense"
                id="distance_textfield"
                label="Distance"
                variant="standard"
                color="info"
                onChange={handleDistanceInput}
              />
            </td>
            <td colspan="2">
            <Select
                styles={customStyles}
                options={distanceCategories}
                menuPlacement="auto"
                menuPosition="fixed"
                placeholder="Select category"
                value={selectedDistanceCategory}
                onChange={handleDistanceCategoriesSelect}
                autosize={true}
                className="dialog-dropdown"
                color="green"
              />
            </td>
          </tr>
          <tr>
            <td>Pace</td>
            <td>
              {
                calculatePace()
              }
            </td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
    </>
  );
}

export default PaceCalc;
