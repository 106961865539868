import PlanSideNavigation from "../PlanSideNavigation";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import "./Workout.css";
import { useState, useEffect } from "react";
import {
  getWorkout,
  deleteWorkout,
  addSetToWorkoutExercise,
  addExerciseToWorkout,
  removeExerciseFromWorkout,
  startWorkout,
  finishWorkout,
  renameWorkout,
} from "../../../../api/WorkoutEndpoints";
import Card from "@mui/material/Card";
import { AiOutlineClose } from "react-icons/ai";
import { MdModeEditOutline } from "react-icons/md";
import WorkoutExerciseRow from "./WorkoutExerciseRow";
import { Button } from "../../../Button";
import React from "react";
import "../../../../App.css";
import { searchExercises } from "../../../../api/ExerciseEndpoints";
import Dialog from "@mui/material/Dialog";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import "../../../../components/Dialog.css";
import { Checkbox } from "@mui/material";
import AuthenticatedResource from "../../../AuthenticatedResource";

function Workout() {
  const { id } = useParams();
  const [workout, setWorkout] = useState();
  const navigate = useNavigate();
  const [addExercisesDialogOpen, setAddExercisesDialogOpen] = useState(false);
  const [exercises, setExercises] = useState([]);
  const [selectedExerciseIds, setSelectedExerciseIds] = useState([]);
  const [superSet, setSuperSet] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);

  const handleAddSetToWorkoutExercise = async (id, workoutExerciseId) => {
    await addSetToWorkoutExercise(id, workoutExerciseId);
    setWorkout(await getWorkout(id));
  };

  async function deleteWorkoutAndRefresh() {
    await deleteWorkout(id);
    navigate("/plan/workouts");
  }

  const handleDialogClose = () => {
    setAddExercisesDialogOpen(false);
  };

  async function handleCategorySelect(data) {
    setSelectedCategory(data);
    let searchString = document.getElementById("searchString").value;
    let exercises = await searchExercises(searchString, data.value);
    setExercises(exercises.content);
  }

  async function handleExerciseSearchString(data) {
    let searchString = document.getElementById("searchString").value;
    let exercises = await searchExercises(searchString, selectedCategory.value);
    setExercises(exercises.content);
  }

  async function handleSuperSetChanged(data) {
    setSuperSet(data.target.checked);
  }

  const handleAddExercisesToWorkout = async () => {
    await addExerciseToWorkout(workout.id, selectedExerciseIds, superSet);
    setAddExercisesDialogOpen(false);
    setSuperSet(false);
    setSelectedExerciseIds([]);
    setWorkout(await getWorkout(id));
  };

  const handleStartWorkout = async () => {
    await startWorkout(workout.id);
    setWorkout(await getWorkout(id));
  };

  const handleFinishWorkout = async () => {
    await finishWorkout(workout.id);
    setWorkout(await getWorkout(id));
  };

  const removeExercise = async (id, workoutExerciseId) => {
    await removeExerciseFromWorkout(id, workoutExerciseId);
    setWorkout(await getWorkout(id));
  };

  const handleSaveWorkout = async () => {
    const nameEl = document.getElementById("name");
    await renameWorkout(id, nameEl.value);
    setWorkout(await getWorkout(id));
    setRenameDialogOpen(false);
  };

  useEffect(() => {
    async function getWorkoutById() {
      getWorkout(id)
        .then((response) => {
          setWorkout(response);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    getWorkoutById();
  }, [id]);

  const categories = [
    { value: "BACK", label: "Back" },
    { value: "BICEPS", label: "Biceps" },
    { value: "CARDIO", label: "Cardio" },
    { value: "CHEST", label: "Chest" },
    { value: "CORE", label: "Core" },
    { value: "FOREARMS", label: "Forearms" },
    { value: "FULL_BODY", label: "Full body" },
    { value: "LEGS", label: "Legs" },
    { value: "OLYMPIC", label: "Olympic" },
    { value: "SHOULDER", label: "Shoulder" },
    { value: "TRICEPS", label: "Triceps" },
  ];

  const supersetColors = [
    "#BFB7C9",
    "#C9B7B8",
    "#B7C9B7",
    "#B7B8C9",
    "#C9BEB7",
    "#C2C9B7",
  ];

  return (
    <>
      <Dialog open={addExercisesDialogOpen} onClose={handleDialogClose}>
        <div className="dialog">
          <div className="dialog-header-row">
            <h2 className="dialog-title">Add exercises</h2>
            <h4 className="corner-indicator">({selectedExerciseIds.length})</h4>
          </div>
          <div className="dialog-rows">
            <div className="dialog-row">
              <Select
                options={categories}
                menuPlacement="auto"
                menuPosition="fixed"
                placeholder="Select category"
                value={selectedCategory}
                onChange={handleCategorySelect}
                maxHeight={500}
                className="dialog-dropdown"
                color="green"
              />
            </div>
            <div className="dialog-row">
              <TextField
                hiddenLabel
                id="searchString"
                size="small"
                color="info"
                fullWidth
                label="Search"
                onChange={handleExerciseSearchString}
                variant="standard"
              />
            </div>
            <div className="dialog-row">
              <ul className="exercises-list">
                {exercises?.map((exercise) => (
                  <li
                    key={exercise.id}
                    onClick={() => {
                      if (selectedExerciseIds.includes(exercise.id)) {
                        setSelectedExerciseIds(
                          selectedExerciseIds.filter(
                            (exerciseId) => exerciseId !== exercise.id
                          )
                        );
                      } else {
                        setSelectedExerciseIds([
                          ...selectedExerciseIds,
                          exercise.id,
                        ]);
                      }
                    }}
                    className={
                      selectedExerciseIds.includes(exercise.id)
                        ? "selected"
                        : "unselected"
                    }
                  >
                    {exercise.name}
                  </li>
                ))}
              </ul>
            </div>
            <div className="dialog-row">
              <Checkbox onClick={handleSuperSetChanged} />
              <div className="vertically-centered">Superset</div>
            </div>
            <div className="dialog-row">
              <Button
                buttonSize="btn--dialog"
                buttonStyle="btn--dialog"
                onClick={handleDialogClose}
              >
                CANCEL
              </Button>
              <Button
                buttonSize="btn--dialog"
                buttonStyle="btn--dialog"
                onClick={handleAddExercisesToWorkout}
              >
                ADD
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={renameDialogOpen}
        onClose={() => {
          setRenameDialogOpen(false);
        }}
      >
        <div className="dialog">
          <div className="dialog-header-row">
            <h2 className="dialog-title">Update workout</h2>
          </div>
          <div className="dialog-rows">
            <div className="dialog-row">
              <TextField
                sx={{
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "black",
                  },
                  "& .MuiInputBase-root": {
                    color: "black",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#2A363B",
                  },
                }}
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                fullWidth
                variant="standard"
                color="info"
                defaultValue={workout?.name}
              />
            </div>
            <div className="dialog-row">
              <Button
                buttonSize="btn--dialog"
                buttonStyle="btn--dialog"
                onClick={() => {
                  setRenameDialogOpen(false);
                }}
              >
                CANCEL
              </Button>
              <Button
                buttonSize="btn--dialog"
                buttonStyle="btn--dialog"
                onClick={handleSaveWorkout}
              >
                UPDATE
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <AuthenticatedResource />
      <div className="plan">
        <PlanSideNavigation />
        <div className="plan-content">
          <div className="plan-content-header">
            <div className="rows">
              <div className="spaced-row">
                <div className="spaced-row-item">
                  <h2>{workout?.name}</h2>
                  </div>
                  <div className="spaced-row-item">
                  {workout && workout?.startedTime === null ? (
                    <Button
                      buttonStyle=""
                      buttonSize="btn--medium2"
                      onClick={() => {
                        handleStartWorkout();
                      }}
                    >
                      Start workout
                    </Button>
                  ) : workout && workout?.startedTime && !workout?.finishedTime ? (
                    <Button
                      buttonStyle=""
                      buttonSize="btn--medium2"
                      onClick={() => {
                        handleFinishWorkout();
                      }}
                    >
                      Finish workout
                    </Button>
                  ) : (
                    <></>
                  )}
                  <Button
                    buttonStyle=""
                    buttonSize="btn--medium2"
                    onClick={() => {
                      setRenameDialogOpen(true);
                    }}
                  >
                    <MdModeEditOutline className="edit-btn-icon-style" size={16} />
                    Edit
                  </Button>
                  <Button
                    buttonStyle=""
                    buttonSize="btn--medium2"
                    onClick={() => {
                      deleteWorkoutAndRefresh();
                    }}
                  >
                    <AiOutlineDelete
                      className="edit-btn-icon-style"
                      size={16}
                    />
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="overview-exercises-list">
            {workout?.exercises?.map((exercise) => {
              return (
                <div
                  style={{ backgroundColor: supersetColors[exercise.superset] }}
                  key={exercise.id}
                >
                  <div className="horizontal-div">
                    <h3>{exercise?.exercise?.name}</h3>
                    {exercise.superset ? <h5>(superset)</h5> : ""}
                  </div>
                  <Card className="card" sx={{ marginTop: 1 }}>
                    <table className="exercise-table">
                      <tbody>
                        <tr className="exercise-table-header">
                          <td className="exercise-table-sortno-column">#</td>
                          <td className="exercise-table-set-type-column">
                            Set type
                          </td>
                          <td className="exercise-table-weight-hint-column" />
                          <td className="exercise-table-weight-column">
                            {exercise.exercise.category === "CARDIO"
                              ? "Distance"
                              : "Weight"}
                          </td>
                          <td className="exercise-table-rep-hint-column" />
                          <td className="exercise-table-rep-column">
                            {exercise.exercise.category === "CARDIO"
                              ? "Time"
                              : "Reps"}
                          </td>
                          <td className="exercise-table-pace-column">
                            {exercise.exercise.category === "CARDIO"
                              ? "Pace"
                              : ""}
                          </td>
                          <td className="exercise-table-executed-column">
                            Executed
                          </td>
                          <td className="exercise-table-delete-column">
                            <AiOutlineClose
                              className="react-button"
                              size={24}
                              onClick={() =>
                                removeExercise(workout.id, exercise.id)
                              }
                            />
                          </td>
                        </tr>

                        {exercise.sets.map((set) => {
                          return (
                            <WorkoutExerciseRow
                              key={set.id}
                              workout={workout}
                              exercise={exercise}
                              set={set}
                              setWorkout={setWorkout}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </Card>
                  <div className="card-button-row">
                    <button
                      className="add-set-button"
                      onClick={() =>
                        handleAddSetToWorkoutExercise(workout.id, exercise.id)
                      }
                    >
                      ADD
                    </button>
                  </div>
                </div>
              );
            })}
            <Button
              buttonSize="btn--dialog"
              onClick={setAddExercisesDialogOpen}
            >
              Add Exercise
            </Button>
          </div>
        </div>
      </div >
    </>
  );
}

export default Workout;
