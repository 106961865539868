import React from "react";
import "../../../../App.css";
import AuthenticatedResource from "../../../AuthenticatedResource";
import PlanSideNavigation from "../PlanSideNavigation";
import "../../Plan.css";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getWorkoutTemplate,
  addExerciseToWorkoutTemplate,
  removeExerciseFromWorkoutTemplate,
  addSetToTemplateExercise,
  renameRoutine,
  deleteWorkoutTemplate
} from "../../../../api/WorkoutTemplateEndpoints";
import { searchExercises } from "../../../../api/ExerciseEndpoints";
import { Button } from "../../../Button";
import Dialog from "@mui/material/Dialog";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import "./Routine.css";
import "../../../../components/Dialog.css";
import { AiOutlineClose, AiOutlineDelete } from "react-icons/ai";
import { MdModeEditOutline } from "react-icons/md";
import TemplateExerciseRow from "./TemplateExerciseRow";
import Card from "@mui/material/Card";
import { Checkbox } from "@mui/material";

function Routine() {
  const [routine, setRoutine] = useState();
  const [exercises, setExercises] = useState([]);
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [selectedExerciseIds, setSelectedExerciseIds] = useState([]);
  const [superSet, setSuperSet] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleAddExercisesToTemplate = async () => {
    await addExerciseToWorkoutTemplate(
      routine.id,
      selectedExerciseIds,
      superSet
    );
    setOpen(false);
    setSelectedExerciseIds([]);
    setRoutine(await getWorkoutTemplate(id));
  };

  const handleRemoveExerciseFromTemplate = async (id, templateExerciseId) => {
    await removeExerciseFromWorkoutTemplate(id, templateExerciseId);
    setRoutine(await getWorkoutTemplate(id));
  };

  const handleDeleteTemplate = async (id) => {
    await deleteWorkoutTemplate(id);
  };

  const handleAddSetToTemplateExercise = async (id, templateExerciseId) => {
    await addSetToTemplateExercise(id, templateExerciseId);
    setRoutine(await getWorkoutTemplate(id));
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  async function handleCategorySelect(data) {
    setSelectedCategory(data);
    let searchString = document.getElementById("searchString").value;
    let exercises = await searchExercises(searchString, data.value);
    setExercises(exercises.content);
  }

  async function handleExerciseSearchString(data) {
    let searchString = document.getElementById("searchString").value;
    let exercises = await searchExercises(
      searchString,
      selectedCategory.value
    );
    setExercises(exercises.content);
  }

  async function handleSuperSetChanged(data) {
    setSuperSet(data.target.checked);
  }

  const [selectedCategory, setSelectedCategory] = useState(null);
  const categories = [
    { value: "BACK", label: "Back" },
    { value: "BICEPS", label: "Biceps" },
    { value: "CARDIO", label: "Cardio" },
    { value: "CHEST", label: "Chest" },
    { value: "CORE", label: "Core" },
    { value: "FOREARMS", label: "Forearms" },
    { value: "FULL_BODY", label: "Full body" },
    { value: "LEGS", label: "Legs" },
    { value: "OLYMPIC", label: "Olympic" },
    { value: "SHOULDER", label: "Shoulder" },
    { value: "TRICEPS", label: "Triceps" },
  ];

  const supersetColors = [
    "#BFB7C9",
    "#C9B7B8",
    "#B7C9B7",
    "#B7B8C9",
    "#C9BEB7",
    "#C2C9B7",
  ];

  useEffect(() => {
    async function getData() {
      getWorkoutTemplate(id)
        .then((response) => {
          setRoutine(response);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function getExercises() {
      searchExercises()
        .then((response) => {
          setExercises(response.content);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getData();
    getExercises();
  }, [id]);

  const handleSaveRoutine = async () => {
    const nameEl = document.getElementById("name");
    await renameRoutine(id, nameEl.value);
    setRoutine(await getWorkoutTemplate(id));
    setRenameDialogOpen(false);
  };

  return (
    <>
      <Dialog open={Boolean(open)} onClose={handleDialogClose}>
        <div className="dialog">
          <div className="dialog-header-row">
            <h2 className="dialog-title">Add exercises</h2>
            <h4 className="corner-indicator">({selectedExerciseIds.length})</h4>
          </div>
          <div className="dialog-rows">
            <div className="dialog-row">
              <Select
                options={categories}
                menuPlacement="auto"
                menuPosition="fixed"
                placeholder="Select category"
                value={selectedCategory}
                onChange={handleCategorySelect}
                maxHeight={500}
                className="dialog-dropdown"
                color="green"
              />
            </div>
            <div className="dialog-row">
              <TextField
                hiddenLabel
                id="searchString"
                size="small"
                color="info"
                fullWidth
                label="Search"
                onChange={handleExerciseSearchString}
                variant="standard"
              />
            </div>
            <div className="dialog-row">
              <ul className="exercises-list">
                {exercises?.map((exercise) => (
                  <li
                    key={exercise.id}
                    onClick={() => {
                      if (selectedExerciseIds.includes(exercise.id)) {
                        setSelectedExerciseIds(
                          selectedExerciseIds.filter(
                            (exerciseId) => exerciseId !== exercise.id
                          )
                        );
                      } else {
                        setSelectedExerciseIds([
                          ...selectedExerciseIds,
                          exercise.id,
                        ]);
                      }
                    }}
                    className={
                      selectedExerciseIds.includes(exercise.id)
                        ? "selected"
                        : "unselected"
                    }
                  >
                    {exercise.name}
                  </li>
                ))}
              </ul>
            </div>
            <div className="dialog-row">
              <Checkbox onClick={handleSuperSetChanged} />
              <div className="vertically-centered">Superset</div>
            </div>
            <div className="dialog-row">
              <Button
                buttonSize="btn--dialog"
                buttonStyle="btn--dialog"
                onClick={handleDialogClose}
              >
                CANCEL
              </Button>
              <Button
                buttonSize="btn--dialog"
                buttonStyle="btn--dialog"
                onClick={handleAddExercisesToTemplate}
              >
                ADD
              </Button>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={renameDialogOpen}
        onClose={() => {
          setRenameDialogOpen(false);
        }}
      >
        <div className="dialog">
          <div className="dialog-header-row">
            <h2 className="dialog-title">Update routine</h2>
          </div>
          <div className="dialog-rows">
            <div className="dialog-row">
              <TextField
                sx={{
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "black",
                  },
                  "& .MuiInputBase-root": {
                    color: "black",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#2A363B",
                  },
                }}
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                fullWidth
                variant="standard"
                color="info"
                defaultValue={routine?.name}
              />
            </div>
            <div className="dialog-row">
              <Button
                buttonSize="btn--dialog"
                buttonStyle="btn--dialog"
                onClick={() => {
                  setRenameDialogOpen(false);
                }}
              >
                CANCEL
              </Button>
              <Button
                buttonSize="btn--dialog"
                buttonStyle="btn--dialog"
                onClick={handleSaveRoutine}
              >
                UPDATE
              </Button>
            </div>
          </div>
        </div>
      </Dialog>

      <AuthenticatedResource />
      <div className="plan">
        <PlanSideNavigation />
        <div className="plan-content">
        <div className="header-row">
            <div className="row">
              <h2>{routine?.name}</h2>
              <MdModeEditOutline
                className="react-button"
                size={24}
                onClick={() => {
                  setRenameDialogOpen(true);
                }}
              />
            </div>
            <div className="row">
              <AiOutlineDelete
                className="react-button"
                size={"1.5rem"}
                onClick={() => {
                  handleDeleteTemplate();
                  navigate("/plan/routines");
                }}
              />
            </div>
          </div>
          <div className="overview-exercises-list">
            {routine?.exercises?.map((exercise) => {
              return (
                <div
                  style={{ backgroundColor: supersetColors[exercise.superset] }}
                  key={exercise.id}
                >
                  <h3>{exercise?.exercise?.name}</h3>
                  <Card className="card" sx={{ marginTop: 1 }}>
                    <table className="exercise-table">
                      <tbody>
                        <tr className="exercise-table-header">
                          <td className="exercise-table-sortno-column">#</td>
                          <td className="exercise-table-set-type-column">
                            Set type
                          </td>
                          <td className="exercise-table-intensity1-column">
                            Intensity
                          </td>
                          <td className="exercise-table-intensity2-column" />
                          <td className="exercise-table-reps1-column">Reps</td>
                          <td className="exercise-table-reps2-column" />
                          <td className="exercise-table-delete-column">
                            <AiOutlineClose
                              className="react-button"
                              size={24}
                              onClick={() =>
                                handleRemoveExerciseFromTemplate(
                                  routine.id,
                                  exercise.id
                                )
                              }
                            />
                          </td>
                        </tr>

                        {exercise.sets.map((set) => {
                          return (
                            <TemplateExerciseRow
                              key={set.id}
                              routine={routine}
                              exercise={exercise}
                              set={set}
                              setRoutine={setRoutine}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </Card>
                  <div className="card-button-row">
                    <button
                      className="add-set-button"
                      onClick={() =>
                        handleAddSetToTemplateExercise(routine.id, exercise.id)
                      }
                    >
                      ADD
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <Button buttonSize="btn--dialog" onClick={setOpen}>
            Add Exercise
          </Button>
        </div>
      </div>
    </>
  );
}

export default Routine;
