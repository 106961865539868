import React from "react";
import "../../App.css";
import AuthenticatedResource from '../AuthenticatedResource';

function Watch() {
  return (
    <>
      <AuthenticatedResource />
      Watch
    </>
  );
}

export default Watch;
