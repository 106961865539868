import React, { useEffect, useState, useCallback } from "react";
import "../../../../App.css";
import { AiOutlineClose } from "react-icons/ai";
import {
  getWorkoutTemplate,
  removeSetFromTemplateExercise,
  updateTemplateSet,
} from "../../../../api/WorkoutTemplateEndpoints";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import { Popup } from "../../../popup/Popup";
import { Tooltip } from "@material-ui/core";
import { debounce } from "lodash";

function TemplateExerciseRow(props) {
  const [weightType, setWeightType] = useState(props.set.weightType);
  const [weightValue, setWeightValue] = useState(props.set.weightValue);
  const [repType, setRepType] = useState(props.set.repType);
  const [repValue, setRepValue] = useState(props.set.repValue);
  const [repValueTo, setRepValueTo] = useState(props.set.repValueTo);
  const [setType, setSetType] = useState();
  const [setTypeDialogOpen, setSetTypeDialogOpen] = useState(false);

  useEffect(() => {
    setWeightType(props.set.weightType);
    setWeightValue(props.set.weightValue);
    setSetType(props.set.setType);
  }, [props.set.weightType, props.set.weightValue, props.set.setType]);

  const delayedHandleWeightValue = useCallback(
    debounce(async (event) => {
      await handleWeightValue(event);
    }, 500),
    []
  );

  const delayedHandleRepValue = useCallback(
    debounce(async (event) => {
      await handleRepValue(event);
    }, 500),
    []
  );

  const delayedHandleRepToValue = useCallback(
    debounce(async (event) => {
      await handleRepValueTo(event);
    }, 500),
    []
  );


  const handleRemoveSetFromTemplateExercise = async (
    id,
    templateExerciseId,
    setId
  ) => {
    await removeSetFromTemplateExercise(id, templateExerciseId, setId);
    props.setRoutine(await getWorkoutTemplate(id));
  };

  async function handleWeightTypeSelect(event) {
    setWeightType(event.value);
    const body = JSON.stringify({ weightType: event.value });
    await updateTemplateSet(
      props.routine.id,
      props.exercise.id,
      props.set.id,
      body
    );
    props.setRoutine(await getWorkoutTemplate(props.routine.id));
  }

  async function handleWeightValue(event) {
    setWeightValue(event.target.value);
    const body = JSON.stringify({ weightValue: event.target.value });
    await updateTemplateSet(
      props.routine.id,
      props.exercise.id,
      props.set.id,
      body
    );
    props.setRoutine(await getWorkoutTemplate(props.routine.id));
  }

  async function handleRepTypeSelect(event) {
    setRepType(event.value);
    const body = JSON.stringify({ repType: event.value });
    await updateTemplateSet(
      props.routine.id,
      props.exercise.id,
      props.set.id,
      body
    );
    props.setRoutine(await getWorkoutTemplate(props.routine.id));
  }

  async function handleRepValue(event) {
    setRepValue(event.target.value);
    const body = JSON.stringify({ repValue: event.target.value });
    await updateTemplateSet(
      props.routine.id,
      props.exercise.id,
      props.set.id,
      body
    );
    props.setRoutine(await getWorkoutTemplate(props.routine.id));
  }

  async function handleRepValueTo(event) {
    const body = JSON.stringify({ repValueTo: event.target.value });
    await updateTemplateSet(
      props.routine.id,
      props.exercise.id,
      props.set.id,
      body
    );
    props.setRoutine(await getWorkoutTemplate(props.routine.id));
  }

  async function handleSetTypeSelect(event) {
    setSetTypeDialogOpen(false);
    setSetType(event);
    const body = JSON.stringify({ setType: event });
    await updateTemplateSet(
      props.routine.id,
      props.exercise.id,
      props.set.id,
      body
    );
    props.setRoutine(await getWorkoutTemplate(props.routine.id));
  }

  const weightTypeOptions = [
    { value: "WEIGHT", label: "Weight" },
    { value: "PERCENT_OF_1RM", label: "%1RM" },
  ];

  const repTypeOptions = [
    { value: "REPS", label: "Reps" },
    { value: "RIR", label: "RiR" },
    { value: "RPE", label: "RPE" },
    { value: "REP_RANGE", label: "Rep Range" },
    { value: "TO_FAILURE", label: "To Failure" },
  ];

  const tinyCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "1.25rem",
      height: "2rem",
      width: "80%",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      fontSize: "0.8rem",
    }),

    input: (provided, state) => ({
      ...provided,
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "2rem",
      width: "0.3rem",
      marginRight: "1.5rem",
    }),
    option: (provided, state) => ({
      ...provided,
      height: "2rem",
      width: "6rem",
      verticalAlign: "center",
      fontSize: "0.8rem",
    }),
  };

  const setTypes = [
    { value: "NORMAL", label: "Normal", shortText: "N" },
    { value: "WARMUP", label: "Warmup", shortText: "W" },
    { value: "DROP_SET", label: "Dropset", shortText: "D" },
  ];

  return (
    <tr>
      <td className="exercise-table-sortno-column">{props.set.sortNo}</td>
      <td className="exercise-table-set-type-column">
      <Tooltip title={setType ? setTypes?.find((e) => e.value === setType)?.label : ""}>
        <h4 className={setType} onClick={() => setSetTypeDialogOpen(true)}>
          {setTypes?.find((e) => e.value === setType)?.shortText}
        </h4>
        </Tooltip>
      </td>
      <td className="exercise-table-intensity1-column">
        <Select
          className="select"
          value={weightTypeOptions.find((e) => e.value === weightType)}
          styles={tinyCustomStyles}
          onChange={handleWeightTypeSelect}
          options={weightTypeOptions}
          menuPosition={"fixed"}
          menuPlacement="auto"
        ></Select>
      </td>
      <td className="exercise-table-intensity2-column">
        <TextField
          hiddenLabel
          id="weightValue"
          label={weightTypeOptions.find((e) => e.value === weightType).label}
          onChange={(event) => {
            setWeightValue(event.target.value);
            delayedHandleWeightValue(event);
          }}
          value={weightValue || ""}
          variant="standard"
        />
      </td>
      <td className="exercise-table-reps1-column">
        <Select
          className="select"
          value={repTypeOptions.find((e) => e.value === repType)}
          styles={tinyCustomStyles}
          onChange={handleRepTypeSelect}
          options={repTypeOptions}
          menuPosition={"fixed"}
          menuPlacement="auto"
        ></Select>
      </td>
      <td className="exercise-table-reps2-column">
        {repType !== "TO_FAILURE" && (
          <div className="rep-value">
            <TextField
              hiddenLabel
              id="repValue"
              label={repType === "REP_RANGE" ? "From" : "Value"}
              onChange={(event) => {
                setRepValue(event.target.value);
                delayedHandleRepValue(event);
              }}
              value={repValue || ""}
              variant="standard"
            />
          </div>
        )}
        {repType === "REP_RANGE" && (
          <div className="rep-value">
            <TextField
              hiddenLabel
              id="repValueTo"
              label="To"
              onChange={(event) => {
                setRepValueTo(event.target.value);
                delayedHandleRepToValue(event);
              }}
              value={repValueTo || ""}
              variant="standard"
            />
          </div>
        )}
      </td>
      <td className="exercise-table-delete-column">
        <AiOutlineClose
          className="react-button"
          size={24}
          onClick={() =>
            handleRemoveSetFromTemplateExercise(
              props.routine.id,
              props.exercise.id,
              props.set.id
            )
          }
        />
      </td>
      {setTypeDialogOpen ? (
        <Popup
          closePopup={() => setSetTypeDialogOpen(false)}
          setSetType={handleSetTypeSelect}
        />
      ) : null}
    </tr>
  );
}

export default TemplateExerciseRow;
