import "../App.css";
import { ACCESS_TOKEN } from "../auth/constants";
import { Navigate } from "react-router-dom";

function AuthenticatedResource() {
  const localStorageToken = localStorage.getItem(ACCESS_TOKEN);

  if (localStorageToken) {
    // do nothing, token is present
  } else {
    return (
      <Navigate
        to={{
          pathname: "/login"
        }}
      />
    );
  }
}

export default AuthenticatedResource;
