import './App.css';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './components/pages/Home';
import Watch from './components/pages/Watch';
import Listen from './components/pages/Listen';
import Account from './components/pages/profile/Account';
import Settings from './components/pages/profile/Settings';
import Community from './components/pages/Community';
import Plans from './components/pages/plan/plan/MyPlans';
import Plan from './components/pages/plan/plan/MyPlan';
import Login from './components/pages/Login';
import OAuth2RedirectHandler from './auth/user/oauth2/OAuth2Redirecthandler';
import CreateRoutine from './components/pages/plan/routine/MyRoutines';
import Routine from './components/pages/plan/routine/Routine';
import Workouts from './components/pages/plan/workout/Workouts';
import Workout from './components/pages/plan/workout/Workout';
import Exercises from './components/pages/plan/exercises/Exercises';
import PaceCalc from './components/pages/tools/PaceCalc';
import Dashboard from './components/pages/plan/dashboard/Dashboard';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/login' exact element={<Login />} />
          <Route path="/oauth2/redirect" exact element={<OAuth2RedirectHandler />} />
          <Route path='/plan/dashboard' exact element={<Dashboard />} />
          <Route path='/plan/routines' exact element={<CreateRoutine />} />
          <Route path='/plan/routines/:id' element={<Routine />} />
          <Route path='/plan/plans' exact element={<Plans />} />
          <Route path='/plan/plans/:id' exact element={<Plan />} />
          <Route path='/plan/workouts' element={<Workouts />} />
          <Route path='/plan/workouts/:id' element={<Workout />} />
          <Route path='/plan/exercises' exact element={<Exercises />} />
          <Route path='/watch' exact element={<Watch />} />
          <Route path='/listen' exact element={<Listen />} />
          <Route path='/community' exact element={<Community />} />
          <Route path='/profile' exact element={<Account />} />
          <Route path='/profile/account' exact element={<Account />} />
          <Route path='/profile/settings' exact element={<Settings />} />
          <Route path='/tools/pacecalc' exact element={<PaceCalc />} />
        </Routes>
      </Router>
    </>
    
  );
}

export default App;
