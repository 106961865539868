import React from "react";
import "../../../../App.css";
import AuthenticatedResource from "../../../AuthenticatedResource";
import PlanSideNavigation from "../PlanSideNavigation";
import "./MyRoutines.css";
import "../../Plan.css";
import { useState, useEffect } from "react";
import { Button } from "../../../Button";
import {
  createWorkoutTemplate,
  searchWorkoutTemplates,
  deleteWorkoutTemplate
} from "../../../../api/WorkoutTemplateEndpoints";
import CreateDialog from "../../../Dialog/CreateDialog";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import Typography from "@mui/material/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";

function CreateRoutine() {
  const [routines, setRoutines] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const navigate = useNavigate();

  const handleCommit = async () => {
    const nameEl = document.getElementById("name");
    await createWorkoutTemplate(nameEl.value);
    updateRoutines();
    setOpen(false);
  };

  const handleDeleteTemplate = async (id) => {
    await deleteWorkoutTemplate(id);
    updateRoutines();
  }

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    updateRoutines();
  }, []);

  async function updateRoutines() {
    searchWorkoutTemplates()
      .then((response) => {
        setRoutines(response.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <AuthenticatedResource />
      <div className="plan">
        <PlanSideNavigation />
        <div className="plan-content">
          <div className="plan-content-header">
            <Button
              buttonStyle=""
              buttonSize="btn--medium2"
              onClick={handleClickOpen}
            >
              NEW ROUTINE
            </Button>
            <CreateDialog
              title="Create New Template"
              open={open}
              handleClose={handleClose}
              handleCommit={handleCommit}
            />
          </div>
          <div className="posts-container">
            {routines?.map((routine) => {
              return (
                <div key={routine.id} className="card-tiles">
                  <Card sx={{ width: 150, height: 150 }}>
                    <CardActionArea sx={{ width: 150, height: 150 }}>
                      <AiOutlineClose
                        className="right-top-corner"
                        onClick={() => handleDeleteTemplate(routine.id)}
                      />
                      <ButtonBase
                        onClick={() => {
                          navigate("/plan/routines/" + routine.id);
                        }}
                      >
                        <CardContent>
                          <Typography gutterBottom variant="h6" component="div">
                            {routine.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="bottom"
                          >
                            {routine.noOfExercises} exercises
                          </Typography>
                        </CardContent>
                      </ButtonBase>
                    </CardActionArea>
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateRoutine;
