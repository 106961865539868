import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { searchWorkoutTemplates } from "../../../../api/WorkoutTemplateEndpoints";
import {
  getPlan,
  addRoutinesToPlan,
  deletePlan,
  deleteRoutineFromPlan,
  renamePlan,
} from "../../../../api/PlanEndpoints";
import AuthenticatedResource from "../../../AuthenticatedResource";
import { Card, TextField } from "@mui/material";
import { AiOutlineClose, AiOutlineDelete } from "react-icons/ai";
import { MdModeEditOutline } from "react-icons/md";
import PlanSideNavigation from "../PlanSideNavigation";
import { Button } from "../../../Button";
import "./MyPlan.css";
import Dialog from "@mui/material/Dialog";

function Plan() {
  const [plan, setPlan] = useState();
  const [routines, setRoutines] = useState();
  const { id } = useParams();
  const [addRoutinesDialogOpen, setAddRoutinesDialogOpen] = useState(false);
  const [selectedRoutineIds, setSelectedRoutineIds] = useState([]);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function getPlanFromAPI() {
      getPlan(id)
        .then((response) => {
          setPlan(response);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function getRoutines() {
      searchWorkoutTemplates()
        .then((response) => {
          setRoutines(response.content);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getPlanFromAPI();
    getRoutines();
  }, [id]);

  const handleDialogClose = () => {
    setAddRoutinesDialogOpen(false);
  };

  const handleAddRoutinesToPlan = async () => {
    await addRoutinesToPlan(id, selectedRoutineIds);
    setSelectedRoutineIds([]);
    setAddRoutinesDialogOpen(false);
    setPlan(await getPlan(id));
  };

  const handleRemoveRoutineFromPlan = async (planId, routineId) => {
    await deleteRoutineFromPlan(planId, routineId);
    setPlan(await getPlan(id));
  };

  const handleSavePlan = async () => {
    const nameEl = document.getElementById("name");
    await renamePlan(id, nameEl.value);
    setPlan(await getPlan(id));
    setRenameDialogOpen(false);
  };

  const handleDeletePlan = async () => {
    await deletePlan(id);
  };

  return (
    <>
      <AuthenticatedResource />
      <Dialog open={Boolean(addRoutinesDialogOpen)} onClose={handleDialogClose}>
        <div className="dialog">
          <div className="dialog-header-row">
            <h2 className="dialog-title">Add routines</h2>
            <h4 className="corner-indicator">({selectedRoutineIds.length})</h4>
          </div>
          <div className="dialog-rows">
            <div className="dialog-row">
              <ul className="exercises-list">
                {routines?.map((routine) => (
                  <li
                    key={routine.id}
                    onClick={() => {
                      if (selectedRoutineIds.includes(routine.id)) {
                        setSelectedRoutineIds(
                          selectedRoutineIds.filter(
                            (routineId) => routineId !== routine.id
                          )
                        );
                      } else {
                        setSelectedRoutineIds([
                          ...selectedRoutineIds,
                          routine.id,
                        ]);
                      }
                    }}
                    className={
                      selectedRoutineIds.includes(routine.id)
                        ? "selected"
                        : "unselected"
                    }
                  >
                    {routine.name}
                  </li>
                ))}
              </ul>
            </div>
            <div className="dialog-row">
              <Button
                buttonSize="btn--dialog"
                buttonStyle="btn--dialog"
                onClick={handleDialogClose}
              >
                CANCEL
              </Button>
              <Button
                buttonSize="btn--dialog"
                buttonStyle="btn--dialog"
                onClick={handleAddRoutinesToPlan}
              >
                ADD
              </Button>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={renameDialogOpen}
        onClose={() => {
          setRenameDialogOpen(false);
        }}
      >
        <div className="dialog">
          <div className="dialog-header-row">
            <h2 className="dialog-title">Update plan</h2>
          </div>
          <div className="dialog-rows">
            <div className="dialog-row">
              <TextField
                sx={{
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "black",
                  },
                  "& .MuiInputBase-root": {
                    color: "black",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#2A363B",
                  },
                }}
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                fullWidth
                variant="standard"
                color="info"
                defaultValue={plan?.name}
              />
            </div>
            <div className="dialog-row">
              <Button
                buttonSize="btn--dialog"
                buttonStyle="btn--dialog"
                onClick={() => {
                  setRenameDialogOpen(false);
                }}
              >
                CANCEL
              </Button>
              <Button
                buttonSize="btn--dialog"
                buttonStyle="btn--dialog"
                onClick={handleSavePlan}
              >
                UPDATE
              </Button>
            </div>
          </div>
        </div>
      </Dialog>

      <div className="plan">
        <PlanSideNavigation />
        <div className="plan-content">
          <div className="header-row">
            <div className="row">
              <h2>{plan?.name}</h2>
              <MdModeEditOutline
                className="react-button"
                size={24}
                onClick={() => {
                  setRenameDialogOpen(true);
                }}
              />
            </div>
            <div className="row">
              <AiOutlineDelete
                className="react-button"
                size={"1.5rem"}
                onClick={() => {
                  handleDeletePlan();
                  navigate("/plan/plans");
                }}
              />
            </div>
          </div>
          <div className="overview-exercises-list">
            <div key={plan?.id}>
              <Card className="card" sx={{ marginTop: 1 }}>
                <table className="exercise-table">
                  <tbody>
                    <tr className="exercise-table-header">
                      <td className="exercise-table-sortno-column">#</td>
                      <td className="exercise-table-name-column">Name</td>
                    </tr>
                    {plan?.routines.map((routine) => {
                      return (
                        <tr key={routine.id}>
                          <td className="exercise-table-sortno-column">
                            {routine.sortNo}
                          </td>
                          <td className="exercise-table-name-column">
                            {routine.routine.name}
                          </td>
                          <td className="exercise-table-delete-column">
                            <AiOutlineClose
                              className="react-button"
                              size={24}
                              onClick={() =>
                                handleRemoveRoutineFromPlan(plan.id, routine.id)
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Card>
              <div className="card-button-row">
                <button
                  className="add-set-button"
                  onClick={() => console.log("add set button clicked")}
                >
                  ADD
                </button>
              </div>
            </div>
          </div>
          <Button buttonSize="btn--dialog" onClick={setAddRoutinesDialogOpen}>
            Add Routine
          </Button>
        </div>
      </div>
    </>
  );
}

export default Plan;
