import ProfileSideMenu from "./ProfileSideMenu";
import AuthenticatedResource from "../../AuthenticatedResource";
import "./Profile.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getSettings, updateSettings } from "../../../api/APIUtils";
import { useEffect, useState } from "react";
import Select from "react-select";
import TextField from "@mui/material/TextField";

function Settings() {
  const [user, setUser] = useState();

  async function getSettingsForUser() {
    getSettings()
      .then((response) => {
        setUser(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getSettingsForUser();
  }, []);

  async function measurementSystemChanged(event) {
    setUser(
      await updateSettings(
        JSON.stringify({ measurementSystem: event.target.value })
      )
    );
    localStorage.setItem("measurementSystem", event.target.value);
  }

  async function handleLocaleSelect(event) {
    setUser(
      await updateSettings(
        JSON.stringify({ locale: event.value })
      )
    );
  }

  async function handleSetsToAddValue(event) {
    var value = null
    if(event.target.value && event.target.value !== "") {
      value = event.target.value
    }

    setUser(
      await updateSettings(
        JSON.stringify({ defaultSetsToAdd: value })
      )
    );
  }

  const locales = [
    { value: "sq-AL", label: "Albanian (sq)" },
    { value: "ar-DZ", label: "Arabic (ar)" },
    { value: "be-BY", label: "Belarusian (be)" },
    { value: "bg-BG", label: "Bulgarian (bg)" },
    { value: "ca-ES", label: "Catalan (ca)" },
    { value: "zh-CN", label: "Chinese (zh)" },
    { value: "hr-HR", label: "Croatian (hr)" },
    { value: "cs-CZ", label: "Czech (cs)" },
    { value: "da-DK", label: "Danish (da)" },
    { value: "nl-NL", label: "Dutch (nl)" },
    { value: "en-GB", label: "English (en)" },
    { value: "en-US", label: "English (us)" },
    { value: "et-EE", label: "Estonian (et)" },
    { value: "fi-FI", label: "Finnish (fi)" },
    { value: "fr-FR", label: "French (fr)" },
    { value: "de-DE", label: "German (de)" },
    { value: "el-GR", label: "Greek (el)" },
    { value: "iw-IL", label: "Hebrew (iw)" },
    { value: "hi-IN", label: "Hindi (hi)" },
    { value: "hu-HU", label: "Hungarian (hu)" },
    { value: "is-IS", label: "Icelandic (is)" },
    { value: "in-ID(*)", label: "Indonesian (in)" },
    { value: "ga-IE(*)", label: "Irish (ga)" },
    { value: "it-IT", label: "Italian (it)" },
    { value: "ja-JP", label: "Japanese (ja)" },
    { value: "ko-KR", label: "Korean (ko)" },
    { value: "lv-LV", label: "Latvian (lv)" },
    { value: "lt-LT", label: "Lithuanian (lt)" },
    { value: "mk-MK", label: "Macedonian (mk)" },
    { value: "ms-MY(*)", label: "Malay (ms)" },
    { value: "mt-MT(*)", label: "Maltese (mt)" },
    { value: "nb-NO", label: "Norwegian Bokmål (nb)" },
    { value: "nn-NO", label: "Norwegian Nynorsk (nn)" },
    { value: "pl-PL", label: "Polish (pl)" },
    { value: "pt-PT(***)", label: "Portuguese (pt)" },
    { value: "ro-RO", label: "Romanian (ro)" },
    { value: "ru-RU", label: "Russian (ru)" },
    { value: "sr-RS(*)", label: "Serbian (sr)" },
    { value: "sk-SK", label: "Slovak (sk)" },
    { value: "sl-SI", label: "Slovenian (sl)" },
    { value: "es-ES", label: "Spanish (es)" },
    { value: "sv-SE", label: "Swedish (sv)" },
    { value: "th-TH", label: "Thai (th)" },
    { value: "tr-TR", label: "Turkish (tr)" },
    { value: "uk-UA", label: "Ukrainian (uk)" },
    { value: "vi-VN", label: "Vietnamese (vi)" }
  ];

  return (
    <>
      <AuthenticatedResource />
      <div className="profile">
        <ProfileSideMenu />
        <div className="profile-content">
          <table id="settings-table">
            <tbody>
              <tr>
                <td>Measurement system</td>
                <td><RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={measurementSystemChanged}
                >
                  <FormControlLabel
                    checked={user?.measurementSystem === "METRIC"}
                    value="METRIC"
                    control={<Radio />}
                    label="Metric"
                  />
                  <FormControlLabel
                    checked={user?.measurementSystem === "IMPERIAL"}
                    value="IMPERIAL"
                    control={<Radio />}
                    label="Imperial"
                  />
                </RadioGroup>
                </td>
              </tr>
              <tr>
                <td>Locale</td>
                <td>
                  <Select
                    options={locales}
                    getOptionLabel={(option) => option.label}
                    menuPlacement="auto"
                    menuPosition="fixed"
                    placeholder="Select Locale"
                    value={locales.find((l) => l.value === user?.locale)}
                    onChange={handleLocaleSelect}
                    maxHeight={500}
                    className="dialog-dropdown"
                    color="green"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  No. of sets to add
                </td>
                <td>
                  <TextField
                    hiddenLabel
                    id="SetsToAddValue"
                    onChange={handleSetsToAddValue}
                    value={user?.defaultSetsToAdd || ""}
                    variant="standard"
                    size="small"
                    type="number"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Settings;
