import './Login.css';
import { Button } from '../Button';
import React from "react";
import GoogleAuthentication from '../../auth/GoogleAuthentication';

function Login() {
  return (
    <div className='login-body'>
            <div className='login-box'>
                <p>Choose login method</p>
                <GoogleAuthentication />
                <Button buttonStyle='btn--test' buttonSize='btn--large'>Facebook</Button>
                <Button buttonStyle='btn--test' buttonSize='btn--large'>AppleID</Button>
            </div>
        </div>
  );
}
export default Login;