import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import {Button} from "../Button";

function CreateDialog(props) {
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <TextField
          sx={{
            "& .MuiFormLabel-root.Mui-focused": {
              color: "black",
            },
            "& .MuiInputBase-root": {
              color: "black",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#2A363B",
            },
          }}
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          variant="standard"
          color="info"
        />
      </DialogContent>
      <DialogActions>
        <div className="dialog-row">
          <Button
            buttonSize="btn--dialog"
            buttonStyle="btn--dialog"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            buttonSize="btn--dialog"
            buttonStyle="btn--dialog"
            onClick={props.handleCommit}
          >
            Create
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default CreateDialog;
